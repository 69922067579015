<template>
  <div class="FieldMana_I">
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('company:fields:add')"><i class="el-icon-plus"></i>新增字段</div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header">
        <el-table-column prop="id" label="ID" width="60" align="center"></el-table-column>
        <el-table-column align="center" prop="title" label="字段名称"></el-table-column>
        <el-table-column align="center" prop="type" label="类型"></el-table-column>
        <el-table-column align="center" prop="is_require" label="必填">
          <template #default="scope">
            <span>{{ {0:'否',1:'是'}[scope.row.is_require||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="is_show" label="员工可见" >
          <template #default="scope">
            <span>{{ {0:'不可见',1:'可见'}[scope.row.is_show||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="is_edit" label="员工可编辑">
          <template #default="scope">
            <span>{{ {0:'不可编辑',1:'可编辑'}[scope.row.is_edit||0] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template #default="scope" >
            <div class="el_butto">
              <el-button type="text" @click="HandleSee(scope.row)">查看</el-button>
            </div>
            <div class="el_butto">
              <el-button type="text" @click="HandleEdit(scope.row)" v-if="TextMoMo('company:fields:edit')">编辑</el-button>
            </div>
            <div class="el_butto rere">
                <el-button
                type="text" v-if="TextMoMo('company:fields:del')"
                class="red"
                @click="HandleDel(scope.row)" >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>


    <el-dialog :title="titleMath" v-model="ruleFormRef2" width="30%">
      <el-form :model="Row" :rules="rules" ref="Row" label-width="100px" class="EditNewForm" style="padding:0 ">
        <el-form-item class="EditNewFormBtns" prop="name"  label="字段名称：">
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.name" placeholder="请填写字段名称" :disabled="control"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="title" label="字段标题：">
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.title" placeholder="请填写字段标题" :disabled="control"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="type" label="字段类型：">
          <div class="EditNewFormItemIpt">
            <el-select v-model="Row.type" placeholder="请选择字段类型" :disabled="control">
              <el-option v-for="item in typeList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="status" label="启用状态：">
          <div class="EditNewFormItemIpt">
            <el-select v-model="Row.status" placeholder="请选择启用状态" :disabled="control">
              <el-option v-for="(item , key) in statusList" :key="key" :label="item" :value="key"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="tip" label="提示语：">
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.tip" placeholder="请输入备注文字，以让人很好理解如何录入" :disabled="control"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="选项内容：">
          <div class="EditNewFormItemIpt">
            <el-input  v-for="(item,i) in R3_inou" :key="i" v-model="item.content" :placeholder="item.name" :disabled="control" style="margin-bottom: 20px;"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" v-if="control == false">
          <div class="EditNewFormItemIpt plus">
            <div @click="Add_cent()"><i class="el-icon-circle-plus-outline"></i>添加选项内容</div>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns DDD" style="margin-top: -22px;" label="是否为必填项：" label-width="130px">
          <div class="EditNewFormItemIpt aaa">
            <el-radio v-model="Row.is_require" :label="1" :disabled="control">必填项</el-radio>
            <el-radio v-model="Row.is_require" :label="0" :disabled="control">不是必填项</el-radio>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns DDD" label="员工是否可见：" label-width="130px">
          <div class="EditNewFormItemIpt aaa">
            <el-radio v-model="Row.is_show" :label="1" :disabled="control">员工可见</el-radio>
            <el-radio v-model="Row.is_show" :label="0" :disabled="control">员工不可见</el-radio>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns DDD" label="员工是否可编辑：" label-width="130px">
          <div class="EditNewFormItemIpt aaa">
            <el-radio v-model="Row.is_edit" :label="1" :disabled="control">员工可编辑</el-radio>
            <el-radio v-model="Row.is_edit" :label="0" :disabled="control">员工不可编辑</el-radio>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="resetForm == 'chuangjian'" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="resetForm == 'baocun'" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../../components/utils.vue";
export default {
  data(){
    return{
      tableData:[],//总列表
      typeList:[],
      R3_inou:[{name:'选项1',content:''},{name:'选项2',content:''}],
      total: '',
      titleMath:'',
      resetForm:'',
      editId:'',
      ruleFormRef2:false,
      control:false,
      page: {  // 分页
        currentPage: 1,
        pageSize: 10,
      },
      search: {  //搜索参数
        keyword: "",
      },
      statusList:{
        hidden: "未启用",
        normal: "启用"
      },
      rules:{
        name: [{ required: true, message: '请填写字段名称', trigger: 'blur' }],
        title: [{ required: true, message: '请填写字段标题', trigger: 'blur' }],
        tip: [{ required: true, message: '请填写提示语：', trigger: 'blur' }],
        type: [{ required: true, message: '请选择字段类型', trigger: 'change' }],
        status: [{ required: true, message: '请选择启用状态', trigger: 'change' }],
      },
      Row:{
        name:'',
        title:'',
        tip:'',
        type:'',
        content:[],
        is_require:'',
        is_show:'',
        is_edit:'',
        status:'',
      }
    }
  },
  mounted(){
    var that = this
    that.loadTable()
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'GET',
        url: '/admin/company/staff/fieldsList',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.typeList = res.data.data.typeList
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //创建打开
    showAdd(){
      var that = this
      that.ruleFormRef2 = true
      that.titleMath = '添加字段'
      that.resetForm = 'chuangjian'
      that.Row = {
        name:'',
        title:'',
        tip:'',
        type:'',
        content:[],
        is_require:1,
        is_show:1,
        is_edit:1,
        status:'',
      }
    },
    //创建
    BtnAdd(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/company/staff/addFields',
            data: {
              row: {
                name : that.Row.name,
                title : that.Row.title,
                tip : that.Row.tip,
                type : that.Row.type,
                content : that.R3_inou,
                is_require : that.Row.is_require,
                is_show : that.Row.is_show,
                is_edit : that.Row.is_edit,
                status : that.Row.status,
              }
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef2 = false
              that.Row = {}
              that.loadTable();
            }
          })
        }
      })
    },
    //编辑详情
    HandleEdit(data){
      var that = this
      that.control = false
      that.ruleFormRef2 = true
      that.titleMath = '编辑字段'
      that.resetForm = 'baocun'
      that.editId = data.id
      axios.get('admin/company/staff/fieldsInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row.name = res.data.data.row.name
          that.Row.title = res.data.data.row.title
          that.Row.tip = res.data.data.row.tip
          that.Row.type = res.data.data.row.type
          const arr = res.data.data.row.content 
          if(arr == null){
            that.R3_inou = [{name:'选项1',content:''},{name:'选项2',content:''}]
          }else{
            that.R3_inou = res.data.data.row.content
          }
          that.Row.is_require = res.data.data.row.is_require
          that.Row.is_show = res.data.data.row.is_show
          that.Row.is_edit = res.data.data.row.is_edit
          that.Row.status = res.data.data.row.status
        }
      })
    },
    HandleSee(data){  //查看
      var that = this
      that.control = true
      that.ruleFormRef2 = true
      that.titleMath = '查看字段信息'
      that.editId = data.id
      axios.get('admin/company/staff/fieldsInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row.name = res.data.data.row.name
          that.Row.title = res.data.data.row.title
          that.Row.tip = res.data.data.row.tip
          that.Row.type = res.data.data.row.type
          const arr = res.data.data.row.content 
          if(arr == null){
            that.R3_inou = [{name:'选项1',content:''},{name:'选项2',content:''}]
          }else{
            that.R3_inou = res.data.data.row.content
          }
          that.Row.is_require = res.data.data.row.is_require
          that.Row.is_show = res.data.data.row.is_show
          that.Row.is_edit = res.data.data.row.is_edit
          that.Row.status = res.data.data.row.status
        }
      })
    },
    //编辑
    BtnEdit(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'PUT',
            url: '/admin/company/staff/editFields',
            data: {
              id:that.editId,
              row:{
                name : that.Row.name,
                title : that.Row.title,
                tip : that.Row.tip,
                type : that.Row.type,
                content : that.R3_inou,
                is_require : that.Row.is_require,
                is_show : that.Row.is_show,
                is_edit : that.Row.is_edit,
                status : that.Row.status,
              }
            },
          }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef2 = false
              that.loadTable()
            }
          })
        }
      })
    },
    //删除弹出框
    HandleDel(data) {
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/company/staff/delFields',
          data: {
            id : data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    Add_cent() {    //添加选项内容
      var that = this
      var arr = that.R3_inou
      var num = 0
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        num = i + 2
      }
      console.log(num)
      var att = { name:'选项' + num, content:''}
      arr.push(att)
      that.R3_inou = arr
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleCurrentChange(page) {  //分页
      var that = this
      that.page.currentPage = page
      axios({
        method: 'GET',
        url: '/admin/company/staff/fieldsList',
        params:{
          page: page,
          offset:0,
          limit:10,
        }
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.typeList = res.data.data.typeList
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    Close(){
      this.ruleFormRef2 = false
      this.Row = {}
      this.R3_inou = []
    }
  }
}
</script>

<style scoped>
.plus{
  color: #1890ff;
  cursor: pointer;
  margin-top: -22px;
}
.plus i{
  margin-right: 5px;
}
.aaa{
  margin-top: 10px;
}
.DDD{
  margin-bottom: 0 !important;
}
</style>