<template>
  <div class="Department_I">
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="showAdd(1)"><i class="el-icon-document"></i>添加子部门</div>
        <div class="NavLiQuery" @click="showAdd(2)"><i class="el-icon-plus"></i>新建部门</div>
      </div>
      <el-table
        :data="tableData"
        row-key="id"
        class="table ddddd"
        style="width: 100%"
        ref="multipleTable"
        @row-click="handleRowClick"
        header-cell-class-name="table-header"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column align="left" prop="name" label="部门"></el-table-column>
        <el-table-column align="center" prop="leader.realname" label="部门负责人"></el-table-column>
        <el-table-column align="center" prop="phone" label="联系方式"></el-table-column>
        <el-table-column align="center" prop="staff_nums" label="部门人数"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
              <div class="el_butto" >
                <el-button
                type="text"
                @click="HandleEdit(scope.row)" 
                v-if="TextMoMo('company:department:edit')"
                >编辑</el-button>
              </div>
            <div class="el_butto rere" >
                <el-button
                type="text"
                class="red"
                v-if="TextMoMo('company:department:del')"
                @click="HandleDel(scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="titleMath" v-model="ruleFormRef2" width="30%">
      <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row" label-width="110px">
        <el-form-item class="EditNewFormBtns" prop="name" label="部门名称：">
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.name" placeholder="请填写部门名称"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="phone" label="联系方式：">
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.phone" type="number" placeholder="请填写联系方式"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="pid" label="上级部门：" v-if="isShow == 1">
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.pid" placeholder="请选择上级部门"  @focus="Bmen1(1)"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="leader_id" label="部门负责人：">
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.leader_id" placeholder="请选择部门负责人" @focus="Bmen1(2)"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" prop="intro" label="简介：">
          <div class="EditNewFormItemIpt">
            <el-input type="textarea" v-model="Row.intro" placeholder="请填写部门简介"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="resetForm" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="!resetForm" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../../components/utils.vue";
export default {
  data(){
    return{
      tableData:[],//总列表
      DMent:[], //部门列表
      Parents:[], //通讯录层级
      StaffList:[],
      DepartmentList:[],
      checkList:[],
      total: '',
      resetForm:'',
      titleMath:'',
      department:'',
      Chan_jieshouren:'',
      multipleSelection_id:'',
      isShow:'',
      leaderId:'',
      Rowpid:'',
      staffId:0,
      c_input:'',
      d_input:'',
      editId:'',
      ruleFormRef2:false,
      dialogVisible10:false,
      dialogVisible8:false,
      Blo_Black:false,    //返回显示  
      Bumen_Tonx: null,
      Chan_Tonx:null,
      page: {  // 分页
        currentPage: 1,
        pageSize: 10,
      },
      search: {  //搜索参数
        keyword: "",
      },
      rules:{
        pid: [{ required: true, message: '请填写部门名称', trigger: 'blur' }],
        name: [{ required: true, message: '请填写联系方式', trigger: 'blur' }],
        leader_id: [{ required: true, message: '请选择上级部门', trigger: 'blur' }],
        phone: [{ required: true, message: '请选择部门负责人', trigger: 'blur' }],
        intro: [{ required: true, message: '请填写部门简介', trigger: 'blur' }],
      },
      Row:{
        pid:'',
        name:'',
        leader_id:'',
        phone:'',
        intro:'',
      }
    }
  },
  mounted(){
    var that = this
    that.loadTable()
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'GET',
        url: '/admin/company/department/departmentList',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.rows.length
        that.$forceUpdate()
      })
    },
    //创建打开
    showAdd(e){
      var that = this
      if( e == 1 ){
        that.ruleFormRef2 = true
        that.isShow = 1
        that.titleMath = '添加子部门'
        that.resetForm = 'subsidiary'
      }else if( e == 2){
        that.ruleFormRef2 = true
        that.isShow = 0
        that.titleMath = '添加部门'
        that.resetForm = 'chuangjian'
      }
    },
    //创建子部门
    subsidiaryAdd(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/company/department/add',
            data: {
              row: {
                pid: that.Rowpid,
                name: that.Row.name,
                leader_id: that.leaderId,
                phone: that.Row.phone,
                intro: that.Row.intro,
              }
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef2 = false
              that.Row = {}
              that.loadTable();
            }
          })
        }
      })
    },
    //创建部门
    BtnAdd(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/company/department/add',
            data: {
              row: {
                name: that.Row.name,
                leader_id: that.leaderId,
                phone: that.Row.phone,
                intro: that.Row.intro,
              }
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef2 = false
              that.Row = {}
              that.loadTable();
            }
          })
        }
      })
    },
    //编辑详情
    HandleEdit(data){
      var that = this
      that.ruleFormRef2 = true
      that.titleMath = '编辑部门'
      that.resetForm = 'baocun'
      that.editId = data.id
      axios.get('/admin/company/department/info', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row = {
            pid : res.data.data.row.parent ? res.data.data.row.parent.name : '',
            name : res.data.data.row.name,
            leader_id : res.data.data.row.leader ? res.data.data.row.leader.realname : '',
            phone : res.data.data.row.phone,
            intro : res.data.data.row.intro,
          }
          that.leaderId = res.data.data.row.leader_id
          that.Rowpid = res.data.data.row.pid
          if(that.Row.pid != ''){
            that.isShow = 1
          }
        }
      })
    },
    BtnEdit(){
      var that = this
      axios({
        method: 'PUT',
        url: '/admin/company/department/edit',
        data: {
          id:that.editId,
          row:{
            pid: that.Rowpid,
            name: that.Row.name,
            leader_id:  that.leaderId,
            phone: that.Row.phone,
            intro: that.Row.intro,
          }
        },
      }).then(function (res) {
        if (res.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.ruleFormRef2 = false
          that.loadTable()
        }
      })
    },
    //删除弹出框
    HandleDel(data) {
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/company/department/del',
          data: {
            id : data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    Bmen1(e) {  //选择部门  负责人
      var that = this
      if(e == 1) {
        that.dialogVisible10 = true;
        that.titleMath = '选择上级部门'
        that.bumen()
      }else if(e == 2) {
        that.dialogVisible8 = true;
        that.department = 2
        that.titleMath = '选择部门负责人'
        that.mailList()
      }
    },
    // 勾选变更功能部门内
    handleClick(data){  
      var that = this
      that.Bumen_Tonx = data
    },
    // 删除已选部门
    Close_Bumen() {  
      var that = this
      that.Bumen_Tonx = null
      that.Chan_Tonx = null
    },
    bumen(){   //部门列表
      var that = this
      axios.get('/admin/company/department/index',{
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DMent = res.data.data.rows 
        }
      })
    },
    mailList(){  //通讯录列表
      var that = this
      axios.get('/admin/company/staff/subListFilter',{ 
        params:{
          id: that.staffId
        }
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.Parents = res.data.data.parents
          that.DepartmentList = res.data.data.departmentList
          that.StaffList = res.data.data.staffList
        }
      })
    },
    Nice_x(e) {             //通讯录下级
      var that = this
      that.Blo_Black = true
      that.staffId = e
      that.mailList()
    },
    Bloo_Black(e) {        
      var that = this
      that.staffId = e
      that.mailList()
    },
    bindCheckBox(value){     //通讯录选择员工
      var that = this
      if(that.checkList.length > 1){
          that.checkList.splice(0,1)
      }
      var arr = that.checkList.toString()
      var brr = that.StaffList
      for (let i = 0; i < brr.length; i++) {
        if (brr[i].id == arr) {
          that.Chan_Tonx = brr[i]
        }
      }
      if (value == '') {
        that.Chan_Tonx = null
      }

    },
    BTN_Bumen() {   //选择上级部门确认
      var that = this
      that.Rowpid = that.Bumen_Tonx.id
      that.Row.pid = that.Bumen_Tonx.name
      that.dialogVisible10 = false;
      setTimeout(function(){
        that.dialogVisible10 = false;
      },1)   
    },
    //员工保存
    staffEdit(){
      var that = this
      that.Row.leader_id = that.Chan_Tonx.realname
      that.leaderId = that.Chan_Tonx.id
      that.dialogVisible8 = false;
      setTimeout(function(){
        that.dialogVisible8 = false;
      },1) 
    },
    Close(){  //关闭按钮
      var that = this
      that.ruleFormRef2 = false;
      that.Row = {}
    },
    Close_s(){
      var that = this
      that.dialogVisible10 = false
      that.dialogVisible8 = false
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleCurrentChange(page) {  //分页
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
  }
}
</script>

<style scoped>
.EditNewForm{
  padding: 0;
}
</style>