<template>
  <div class="mailList_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">人员管理</el-menu-item>
          <el-menu-item index="2">部门管理</el-menu-item>
          <el-menu-item index="3">字段管理</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails"> 销售线索：① 管理系统目前所有的销售线索，可以进行编辑、删除、员工认领；② 可以通过本频道手动添加或批量上传销售线索，用于员工联系与跟踪。</div>
    </div>


    <Resigned v-if="info_show == 4" @childFn="parentFn"></Resigned><!-- 已离职员工 -->
    <Department v-if="info_show == 1"></Department> <!-- 部门管理 -->
    <FieldMana v-if="info_show == 2"></FieldMana> <!-- 字段管理 -->
    <div class="mailList" v-if="info_show == 0">
      <div class="mailListLeft">
        <div class="mailListIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="C_input" placeholder="请输入部门名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>

        <div class="mailListTree">
          <el-tree
            style="width:100%"
            class="kkkkkk"
            :data="DMent"
            node-key="id"
            :default-expand-all="false"
            :expand-on-click-node="true"
            @node-click="handleNodeClick">
            <template #default="{ node, data }"> 
              <div class="customtreenode">
                <span class="custom-tree-node"><span>{{ node.data.name }}</span></span>
              </div>
              <div @click.stop>
                <el-dropdown trigger="click" @click="More(att.id)">
                  <i class="Rund el-icon-more"></i>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="a" @click.stop="bianji(data,node)">编辑</el-dropdown-item>
                      <el-dropdown-item command="a" @click.stop="append(data,node)">添加</el-dropdown-item>
                      <el-dropdown-item command="b"  @click.stop="remove(node, data)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div> 
            </template> 
          </el-tree>
        </div>
      </div>
      <!-- 右侧列表 -->
      <div class="mailListRight">
        <div class="TablesList">
          <div class="TablesADD">
            <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
            <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('company:staff:add')"><i class="el-icon-plus"></i>添加成员</div>
            <div class="NavLiReset" @click="Bian_bumen()"><i class="el-icon-wallet"></i>变更部门</div>
            <div class="NavLiReset" @click="info_show = 4"><i class="el-icon-user"></i>已离职员工</div>
            <div class="TableIpt"> 
              <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入姓名">
              <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
            </div>
          </div>
          <el-table
            :data="tableData"
            class="basetable"
            ref="multipleTable"
            header-cell-class-name="basetable-header"
            row-key="id"
            :reserve-selection="true"
            @selection-change="Selection">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column prop="id" label="ID" width="60" align="center"></el-table-column>
            <el-table-column align="center" prop="realname" label="姓名"></el-table-column>
            <el-table-column align="center" prop="department.name" label="部门"></el-table-column>
            <el-table-column align="center" prop="mobile" label="手机号"></el-table-column>
            <el-table-column align="center" prop="job" label="职责"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template #default="scope">
                <div class="el_butto" >
                  <el-button
                  type="text"
                  @click="HandleSee(scope.row)" 
                  >查看</el-button>
                </div>
                <div class="el_butto" >
                  <el-button
                  type="text"
                  @click="HandleEdit(scope.row)" 
                  v-if="TextMoMo('company:staff:edit')"
                  >编辑</el-button>
                </div>
                <div class="el_butto rere" >
                    <el-button
                    type="text"
                    class="red"
                    v-if="TextMoMo('company:staff:del')"
                    @click="HandleDel(scope.row)"
                    >删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.currentPage"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
          
        </div>
      </div>
    </div>
    

    <div class="show" v-if="ruleFormRef2">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
          <div class="NavLiQuery Quit" @click="HandleQuit()" v-if="isInfo == 1">办理离职</div>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row" v-if="isShow == 1">
          <el-form-item class="EditNewFormItem" prop="realname">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>姓名</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.realname" placeholder="请填写姓名" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="mobile">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>手机号码</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.mobile" placeholder="请输入手机号码" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="job">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>职务</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.job" placeholder="请填写职务" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="job_number">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>工号</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.job_number" placeholder="请填写工号" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="work_year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>工作年限</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.work_year" placeholder="请填写员工工作年限" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="email">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>邮箱</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.email" placeholder="请填写邮箱" :disabled="control"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="entry_time">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>入职时间</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="Row.entry_time" type="date" :disabled="control" style="width:100%" placeholder="选择入职时间" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="realname">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>部门</div>
            <div class="EditNewFormItemIpt">
              <el-cascader
                style="width: 100%;"
                ref="Casc"
                :disabled="control"
                v-model="Row.Row_piii"
                :options="DMent"
                :change-on-select="true"
                :props="{ value:'id', label:'name' }"
                @change="handleChange">
              </el-cascader>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="leader_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>直属上级</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.leader_id" placeholder="请选择" :disabled="control">
                <el-option v-for="item in tableData" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="type_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>
              <span>人员类型</span>
              <span class="Modification" @click="dialogVisible1 = true">修改人员类型</span>
            </div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.type_id" placeholder="请选择" @change="Chang_ses2" :disabled="control">
                <el-option v-for="item in MMD" :key="item.value" :label="item.content" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="sex">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>性别</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.sex" placeholder="请选择" :disabled="control">
                <el-option v-for="item in GenderList" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="Row_piii2">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>角色</div>
            <div class="EditNewFormItemIpt">
              <el-cascader
                style="width: 100%;"
                ref="Casc"
                :disabled="control"
                v-model="Row.Row_piii2"
                :options="DMent2w"
                :change-on-select="true"
                :props="{ value:'id', label:'name'}"
                @change="handleChange2"></el-cascader>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="mobile_hide">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>号码隐藏</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.mobile_hide" placeholder="请选择" :disabled="control">
                <el-option v-for="(item,key) in numberList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-if="control != false">
            <div class="EditNewFormItemName">照片上传：</div>
            <div class="EditNewFormItemIpt">
              <img :src="fileListUrl" alt="" class="Avatar">
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-else>
            <div class="EditNewFormItemName">照片上传：</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-avatar"
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传头像</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="resetForm == 1" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="resetForm == 2" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
        <div class="EditNewForm  ReasonQuit" v-if="isShow == 2">
          <div class="EditNewFormBtns NewFormBtn">
            <div class="EditNewFormItemName">（豆腐网）</div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">离职原因</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row2.reason" placeholder="请填写离职原因" ></el-input>
            </div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">离职时间</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="Row2.quit_time" type="date" style="width:100%" placeholder="选择日期"></el-date-picker>
            </div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">离职类型</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row2.type" placeholder="请选择离职类型">
                <el-option v-for="item in quitType" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">办理状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row2.quit_status" placeholder="请选择办理状态">
                <el-option v-for="item in HandlingStatus" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="EditNewFormBtns">
            <div class="Holiday">
              <div>将员工拥有的资源：</div>
              <div>
                <el-radio v-model="Row2.is_transfer" :label="0">直接删除</el-radio>
                <el-radio v-model="Row2.is_transfer" :label="1">转移资源给其他员工</el-radio>
              </div>
            </div>
          </div>
          <div class="EditNewFormBtns" v-if="Row2.is_transfer == 1">
            <el-table
              :data="Row2.transfer"
              class="basetable"
              ref="multipleTable"
              header-cell-class-name="basetable-header">
              <el-table-column prop="name" label="资源"></el-table-column>
              <el-table-column prop="people" label="描述"></el-table-column>
              <el-table-column label="接收人">
                <template #default="scope">
                  <div class="fgfhsdg">
                    <div v-for="(item,i) in Row2.transfer" :key="i" >
                      <div v-if="item.to_staff != null ">
                        <el-input v-if="i == scope.$index" v-model="item.to_staff.realname" placeholder="选择接收人" @focus='Recipient(scope.$index)'></el-input>
                      </div>
                      <div v-if="item.to_staff == null ">
                        <el-input v-if="i == scope.$index" v-model="RRealname" placeholder="选择接收人" disabled></el-input>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="EditNewFormBtns">
             <div class="FormBtns">
              <div class="FormBtnsQuery" @click="quitEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--修改人员类型-->
    <el-dialog title="修改人员类型" v-model="dialogVisible1" width="36%"> 
      <div class="Tabbl">
        <el-table
        :data="MMD"
        class="basetable"
        ref="multipleTable"
        header-cell-class-name="basetable-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="选项类别" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="96">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="handleDelete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="Y_ids" @click="Add_leibie"><i class="el-icon-plus"></i>新增选项</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN" style="margin-left:15px">确 定</el-button>
        </div>
      </div>  
    </el-dialog>

    <!--变更部门-->
    <el-dialog :title="titleMath" v-model="dialogVisible6" width="48.5%"> 
      <div v-if="department == 1">
        <div class="Trtfd">
          <div class="Tytt">
            <div class="hhhh">
              <div class="dsds" style="float:left;width: 87%;"> <el-input v-model="input" placeholder="请输入内容"></el-input> </div>
              <div class="el_seso"><i class="el-icon-search"></i></div>
            </div>
            <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" style="cursor: pointer;">{{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span></div>
            </div>
            <div class="custom-tree-container">
              <div class="block" style="margin-top:16px;margin-left:15px">
              <el-tree
                :data="DMent"
                node-key="code"
                ref="tree"
                check-strictly
                show-checkbox
                :default-expand-all="false"
                :expand-on-click-node="false"
                @check-change="handleClick" >
                <template #default="{ node, data }">
                  <span class="custom-tree-node"> <span>{{ node.data.name }}</span> </span>
                </template>
              </el-tree>
              </div>
            </div>
          </div>
          <div class="Tytt" style="padding:29px 0px 20px 20px;">
            <div v-if="Bumen_Tonx != null">已选：1个部门</div>
            <div v-else>已选：0个部门</div>
            <div class="tyhh" v-if="Bumen_Tonx != null">
              <span>{{Bumen_Tonx.name}}</span>
              <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
            </div>
          </div>
        </div>
        <div class="Dityo">
          <div class="dialog-footer">
            <el-button type="primary" @click="BTN_Bumen()" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 
      </div>

      <div v-if="department == 2">
        <div class="Trtfd">
          <div class="Tytt">
            <div class="hhhh">
              <div class="dsds" style="float:left;width: 87%;">
                <el-input v-model="input" placeholder="请输入内容"></el-input>
              </div>
              <div class="el_seso"><i class="el-icon-search"></i></div>
            </div>
            <div class="Blacks" >
                <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                  {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
                </div>
              </div>
            <div class="custom-tree-container">
              <div class="block" style="margin-top:16px;margin-left:15px">
              <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
                <div>
                  <img class="Blo_a1" src="" alt="">
                  <span class="Blo_a2">{{item.name}}</span>
                </div>
                <div @click="Nice_x(item.id)" style="cursor: pointer;">
                  <img class="Blo_a3" src="" alt="">
                  <span class="Blo_a4">下级</span>
                </div>
              </div>
              <div>
                <el-checkbox-group v-model="checkList" @change="bindCheckBox">
                  <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                    <el-checkbox :label="item.id">
                      <img :src="item.full_avatar" alt="">
                      <span>{{item.realname}}</span>
                      </el-checkbox>
                  </div>                 
                </el-checkbox-group>
              </div>
              </div>
            </div>
          </div>
          <div class="Tytt" style="padding:29px 0px 20px 20px;">
            <div v-if="Chan_Tonx != null">已选：1个员工</div>
            <div v-if="Chan_Tonx == null">已选：0个员工</div>
            <div class="tyhh" v-if="Chan_Tonx != null">
              <img :src="Chan_Tonx.full_avatar" alt="">
              <span>{{Chan_Tonx.realname}}</span>
              <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
            </div>
          </div>
        </div>
        <div class="Dityo">
          <div class="dialog-footer">
            <el-button type="primary" @click="staffEdit()">保存设置</el-button>
          </div>
        </div> 
      </div>
    </el-dialog>

    <!--部门编辑修改名称-->
    <el-dialog title="修改部门名称" v-model="dialogVisible9" width="24.5%">
      <div><el-input v-model="diasible9"></el-input> </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button type="primary" @click="Btn_xiugai()">保存</el-button>
        </div>
      </div>  
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import TextMoth from "../../components/utils.vue";
import Resigned from './components/Resigned.vue'
import Department from './components/Department.vue'
import FieldMana from './components/FieldMana.vue'
export default {
  components:{
    Resigned,
    Department,
    FieldMana
  },
  data(){
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      tableData:[],//总列表
      DData22:[],
      DMent2w:[],
      MMD:[],
      StaffList:[],
      DepartmentList:[],
      checkList:[],
      Parents:[], //通讯录层级
      DMent:[], //部门列表
      fileList:[], //图片
      numberList:["隐藏", "显示"],
      GenderList:[{id:1,name:'男'},{id:2,name:'女'}],
      HandlingStatus:[{name:'待交接',id: 10},{name:'已交接',id: 20},{name:'不需交接',id: 30}],
      quitType:[{id:10,name:'主动离职'}, {id:20,name:'被动��职'}],
      ruleFormRef2:false,
      resetForm:1,
      dialogVisible9:false,
      dialogVisible6:false,
      Blo_Black:false,    //返回显示  
      dialogVisible1:false,
      control:false,
      activeIndex:'1',
      titleMath:'',
      fileListUrl:'',
      total: '',
      department:'',
      isShow: '',
      isInfo:'',
      info_show:0,
      editId:'',
      C_input:'',
      diasible9:'',
      diasibleName:'',
      diasibleId:'',
      multipleSelection_id:'',
      staffId:0,
      Chan_jieshouren:'',
      Bumen_Tonx: null,
      Chan_Tonx:null,
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      //搜索参数
      search: {
        keyword: "",
        department_id:'',
      }, 
      Row:{
        Row_piii:'',  //部门
        Row_piii2:'', //角色
        department_id:'',
        realname:'',
        avatar:'',
        mobile:'',
        email:'',
        sex:'',
        job:'',
        job_number:'',
        work_year:'',
        entry_time:'',
        mobile_hide:'',
        leader_id:'',
        type_id:'',
        group:[],
      },

      rules:{
        mobile: [{ required: true, message: '请输入员工手机号码', trigger: 'blur' }],
        realname: [{ required: true, message: '请填写客服姓名', trigger: 'blur' }],
        job: [{ required: true, message: '请填写客服姓名', trigger: 'blur' }],
        job_number: [{ required: true, message: '请填写员工工号', trigger: 'blur' }],
        work_year: [{ required: true, message: '请填写员工工作年限', trigger: 'blur' }],
        email: [{ required: true, message: '请填写成员的邮箱地址', trigger: 'blur' }],
        entry_time: [{ required: true, message: '选择日期', trigger: 'blur' }],
        Row_piii: [{ required: true, message: '请选择部门', trigger: 'change' }],
        Row_piii2: [{ required: true, message: '请选择角色', trigger: 'change' }],
        leader_id: [{ required: true, message: '请选择直属上级', trigger: 'change' }],
        type_id: [{ required: true, message: '请选择人员类型', trigger: 'change' }],
        group: [{ required: true, message: '请选择部门', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        mobile_hide: [{ required: true, message: '请选择号码是否隐藏', trigger: 'change' }],
      },
      Row2:{
        staff_id:'',
        reason:'',
        type:'',
        quit_status:'',
        quit_time:'',
        is_transfer:'',
        transfer:[{
            name:'订单资源',
            people:'转移所有档案',
            type: "order", //离职类型:10=主动离职,20=被动离职
            to_staff_id: 0, //接收人
            to_staff:{
              realname:'',    //接收人
            }
          },{
            name:'订单资源',
            people:'转移所有档案',
            type: "order", //离职类型:10=主动离职,20=被动离职
            to_staff_id: 0, //接收人
            to_staff:{
              realname:'',    //接收人
            }
          },{
            name:'订单资源',
            people:'转移所有档案',
            type: "order", //离职类型:10=主动离职,20=被动离职
            to_staff_id: 0, //接收人
            to_staff:{
              realname:'',    //接收人
            }
        }],
      }
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.bumen()
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/company/staff/staffList',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    }, 
    //打开创建按钮
    showAdd() {
      var that = this
      that.ruleFormRef2 = true;
      that.resetForm = 1;
      that.titleMath = '添加员工'
      that.isShow = 1
      that.juese()
      that.Row = {
        department_id:'',
        realname:'',
        avatar:'',
        mobile:'',
        email:'',
        sex:'',
        job:'',
        job_number:'',
        work_year:'',
        entry_time:'',
        mobile_hide:'',
        leader_id:'',
        type_id:'',
        group:[],
      };
      that.fileList = [];
    },
    // 创建
    BtnAdd() {
      var that = this
      var date = new Date(that.Row.entry_time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/company/staff/addStaff',
            data: {
              row: {
                department_id: that.Row.department_id,
                realname : that.Row.realname,
                avatar : that.Row.avatar,
                mobile : that.Row.mobile,
                email : that.Row.email,
                sex : that.Row.sex,
                job : that.Row.job,
                job_number : that.Row.job_number,
                work_year : that.Row.work_year,
                entry_time : time,
                mobile_hide : that.Row.mobile_hide,
                leader_id : that.Row.leader_id,
                type_id : that.Row.type_id,
                group : that.Row.group,
              }
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef2 = false
              that.loadTable();
            }
          })
        }
      })
    },
    // 获取编辑内容
    HandleEdit(data) {                        
      var that = this
      that.ruleFormRef2 = true;
      that.resetForm = 2
      that.titleMath = '编辑员工'
      that.editId = data.id
      that.isShow = 1
      that.isInfo = 1
      that.juese()
      axios.get('/admin/company/staff/staffInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row.Row_piii = res.data.data.row.department_id
          that.Row.realname = res.data.data.row.realname
          that.Row.avatar = res.data.data.row.avatar
          that.Row.mobile = res.data.data.row.mobile
          that.Row.email = res.data.data.row.email
          that.Row.sex = parseInt(res.data.data.row.sex)
          that.Row.job = res.data.data.row.job
          that.Row.job_number = res.data.data.row.job_number
          that.Row.work_year = res.data.data.row.work_year
          that.Row.entry_time = res.data.data.row.entry_time
          that.Row.mobile_hide = res.data.data.row.mobile_hide
          that.Row.leader_id = res.data.data.row.leader_id
          that.Row.type_id = res.data.data.row.type_id
          var cccc = Number(res.data.data.row.group.slice(-1))
          that.Row.Row_piii2 = cccc
           //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_avatar;
          fileArr.push(fileObj);
          that.fileList = fileArr
        }
      })
    },
    HandleSee(data){
      var that = this
      that.resetForm = 3
      that.isInfo = 2
      that.control = true
      that.ruleFormRef2 = true;
      that.titleMath = '查看员工信息'
      that.editId = data.id
      that.isShow = 1
      that.juese()
      axios.get('/admin/company/staff/staffInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row.Row_piii = res.data.data.row.department_id
          that.Row.realname = res.data.data.row.realname
          that.Row.avatar = res.data.data.row.avatar
          that.Row.mobile = res.data.data.row.mobile
          that.Row.email = res.data.data.row.email
          that.Row.sex = parseInt(res.data.data.row.sex)
          that.Row.job = res.data.data.row.job
          that.Row.job_number = res.data.data.row.job_number
          that.Row.work_year = res.data.data.row.work_year
          that.Row.entry_time = res.data.data.row.entry_time
          that.Row.mobile_hide = res.data.data.row.mobile_hide
          that.Row.leader_id = res.data.data.row.leader_id
          that.Row.type_id = res.data.data.row.type_id
          var cccc = Number(res.data.data.row.group.slice(-1))
          that.Row.Row_piii2 = cccc
           //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_avatar;
          fileArr.push(fileObj);
          that.fileList = fileArr
          that.fileListUrl = fileArr[0].url
        }
      })
    },
    //编辑
    BtnEdit() {
      var that = this
      var time = this.formatDate(that.Row.entry_time)
      that.$refs['Row'].validate((valid) =>{
        if(valid){
          axios({
            method: 'put',
            url: '/admin/company/staff/editStaff',
            data: {
              id: that.editId,
              row: {
                department_id: that.Row.Row_piii,
                realname : that.Row.realname,
                avatar : that.Row.avatar,
                mobile : that.Row.mobile,
                email : that.Row.email,
                sex : that.Row.sex,
                job : that.Row.job,
                job_number : that.Row.job_number,
                work_year : that.Row.work_year,
                entry_time : time,
                mobile_hide : that.Row.mobile_hide,
                leader_id : that.Row.leader_id,
                type_id : that.Row.type_id,
                group : that.Row.Row_piii2,
              }
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleFormRef2 = false
              that.loadTable();
            }
          })
        }
      })
    },
    //删除弹出框
    HandleDel(data) {
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/company/staff/delStaff',
          data: {
            id : data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //办理离职
    HandleQuit(){
      var that = this
      that.isShow = 2 
      that.isInfo = 0
      that.titleMath = '办理离职'
    },
    //离职
    quitEdit(){
      var that = this
      var add = that.formatDate(that.Row2.quit_time)
      if(add == ''){
        that.$message.warning('请填写离职时间');
        return
      }
      if(that.Row2.reason == ''){
        that.$message.warning('请填写离职原因');
        return
      }
      if(that.Row2.type == ''){
        that.$message.warning('请选择离职类型');
        return
      }
      if(that.Row2.quit_status == ''){
        that.$message.warning('请选择办理状态');
        return
      }
      axios({
        method: 'post',
        url: '/admin/company/staff/addQuit',
        data: {
          row:{
            staff_id : that.editId,
            reason : that.Row2.reason,
            type : that.Row2.type,
            quit_status : that.Row2.quit_status,
            quit_time : add,
            is_transfer : that.Row2.is_transfer,
            transfer: that.Row2.transfer,
          }
        },
      }).then(function (res) {
        if (res.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.ruleFormRef2 = false
          that.loadTable();
        }
      })
    },
    //部门列表
    bumen(){
      var that = this
      axios.get('/admin/company/department/index',{   // 部门列表
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DMent = res.data.data.rows 
          that.DData22 = res.data.data.rows 
        }
      })
    },
    //通讯录列表
    mailList(){
      var that = this
      axios.get('/admin/company/staff/subListFilter',{ 
        params:{
          id: that.staffId
        }
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.Parents = res.data.data.parents
          that.DepartmentList = res.data.data.departmentList
          that.StaffList = res.data.data.staffList
        }
      })
    },
    // 角色
    juese(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/auth/group/index',
      }).then(function (res) {
          if (res.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.DMent2w = res.data.data.rows
          }
      })
      axios.get('/admin/company/content/index?field_key=employee_type',{   // 人员类型
      }).then(function (res) {
        if (res.code == 0) {
          that.$message.warning(res.data.msg);
        } else{ 
          that.MMD = res.data.data.enums
        }
      })
    },
    Close(){  //关闭按钮
      var that = this
      that.ruleFormRef2 = false;
      that.Chan_Tonx = null
      that.Row = {}
      setTimeout(function(){
        that.dialogVisible6 = false;
      },1) 
    },
    //选择接收人
    Recipient(e){
      var that = this
      that.dialogVisible6 = true
      that.department = 2
      that.Chan_jieshouren = e
      that.mailList()
    },
    //员工保存
    staffEdit(){
      var that = this
      that.Row2.transfer[that.Chan_jieshouren].to_staff.realname = that.Chan_Tonx.realname
      that.Row2.transfer[that.Chan_jieshouren].to_staff_id = that.Chan_Tonx.id
      that.dialogVisible6 = false;
      setTimeout(function(){
        that.dialogVisible6 = false;
      },1) 
    },
    //搜索刷新
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          keyword: "",
          department_id:'',
        };
        that.C_input = ''
      }
      that.loadTable();
      if( e == 1){
        axios.get('/admin/company/department/index',{
          params:{
            keyword: that.C_input
          }
        }).then(function (res) {
          if (res.code == 0) {
            alert('请求失败');  
          } else{ 
            that.DMent = res.data.data.rows
          }
        }) 
      }
    },
    Selection(e) {    // 勾选成员
      var that = this
      var arr = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
        }
        that.multipleSelection_id = arr
      }
    },
    handleChange() {
      var that = this
      var num = Number(that.Row.Row_piii.slice(-1))
      that.Row.department_id = num
    },
    handleChange2() {
      var that = this
      var arr = []
      var num = Number(that.Row.Row_piii2.slice(-1))
      arr.push(num)
      that.Row.group = arr
      
    },
    // ---------------------------------------------------左侧js---------------------------------------------------
    changeSwitch(data,b,index) {   // radio
      var that = this
      var Categ = that.MMD
      console.log(Categ)
      console.log(index)
      console.log('当前值' + Categ[index].status)
      if (Categ[index].status == 'normal') {
        Categ[index].status = 'hidden'
        console.log('进1改变为0')
      } else if (Categ[index].status == 'hidden') {
        console.log('进0改变为1')
        Categ[index].status = 'normal'
      }
      that.MMD = Categ
    },
    Add_leibie() {  //创建导航类别
      var that = this
      var ATT = that.MMD
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.MMD = ATT
    },
    NabCH_BTN() {
      var that = this
      var ATT = that.MMD
      axios({
        method: 'post',
        url: '/admin/company/content/edit',
        data: {
          field_key: "employee_type", //内容标识
          content: ATT
        },
      }).then(function (res) {
        if (res.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.dialogVisible1 = false
          that.juese()
        }
      }) 
    },
    handleDelete(index, row) {  // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.MMD
      if (row.type == 'default') {
      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.MMD = ATT
      }
    },
    // 点击部门
    handleNodeClick(data){
      var that = this
      that.search.department_id = data.id
      that.loadTable()
    },
    //部门创建
    append(data){
      var that = this
      const newChild = { id: 0, name: '新加子部门', children: [] }
      if (!data.children) {
        data.children = []
      }
      data.children.push(newChild)
      that.DMent = [...that.DMent]
      axios({ 
        method: 'post',
        url: '/admin/company/department/add',    // 新建部门
        data: {
          row:{
            pid: data.id, //上级部门ID
            name: '新加子部门',
            leader_id: "2", //主管ID
          }
        },
      }).then(function (res) {
          if (res.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.bumen()
          }
      }) 
    },
    //部门删除
    remove(node, data) {  // 部门删除
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'delete',
          url: '/admin/company/department/del',
          data: {
            id: data.id
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.dia_DEl = false  
            that.$message.success(res.data.msg);
            that.bumen()
          }
        }) 
      })
    },
    //部门编辑
    bianji(data){
      var that = this
      that.dialogVisible9 = true
      that.diasible9 = data.name
      that.diasibleName = data.name
      that.diasibleId = data.id
    },
    Btn_xiugai(){
      var that = this
      axios({
        method: 'put',
          url: '/admin/company/department/edit',
          data: {
            id: that.diasibleId,
            row:{
              name: that.diasible9
            }
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.dialogVisible9 = false
            that.bumen()
          }
        }) 
    },
    //打开变更部门
    Bian_bumen(){
      var that = this
      if (that.multipleSelection_id == null) {
        that.$message.warning('请选择人员');
      } else if(that.multipleSelection_id.length == 0) {
        that.$message.warning('请选择人员');
      } else{
        that.dialogVisible6 = true
        that.titleMath = '变更部门'
        that.department = 1
        that.mailList()
        that.bumen()
      }
    },
    // 勾选变更功能部门内
    handleClick(data){  
      var that = this
      that.Bumen_Tonx = data
    },
    // 删除已选部门
    Close_Bumen() {  
      var that = this
      that.Bumen_Tonx = null
      that.Chan_Tonx = null
    },
    BTN_Bumen() {    //确认变更部门
      var that = this
      axios({ 
        method: 'post',
        url: '/admin/company/staff/transfer',    // 员工列表
        data: {
          ids: that.multipleSelection_id,
          department_id:that.Bumen_Tonx.id
        },
      }).then(function (res) {
          if (res.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.dialogVisible6 = false
            that.Bumen_Tonx = null
            that.Chan_Tonx = null
            that.loadTable()
            setTimeout(function(){
              that.dialogVisible6 = false;
            },1)   
          }

      }) 
    },
    handleCurrentChange(page) {  //分页
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    bindCheckBox(value){     //通讯录选择员工
      var that = this
      if(that.checkList.length > 1){
          that.checkList.splice(0,1)
      }
      var arr = that.checkList.toString()
      var brr = that.StaffList
      for (let i = 0; i < brr.length; i++) {
        if (brr[i].id == arr) {
          that.Chan_Tonx = brr[i]
        }
      }
      if (value == '') {
        that.Chan_Tonx = null
      }

    },
    Nice_x(e) {             //通讯录下级
      var that = this
      that.Blo_Black = true
      that.staffId = e
      that.mailList()
    },
    Bloo_Black(e) {        
      var that = this
      that.staffId = e
      that.mailList()
    },
    // 接收值
    parentFn(){
      this.info_show = 0
    },
    //导航选择
    handleSelect(key){
      if(key == 1){
        this.info_show = 0
      }else if(key == 2){
        this.info_show = 1
      }else if(key == 3){
        this.info_show = 2
      }
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.avatar = e.data.url
      // that.$refs.image.clearValidate()
    },
    handleExceed  (files)  {
      this.$refs["upload-avatar"].clearFiles()
      this.$refs["upload-avatar"].handleStart(files[0])
      this.$refs["upload-avatar"].submit();
    },
    handlePreview(file) {
      window.open(file.url);
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
  }
}
</script>

<style scoped>
.mailList{
  margin: 15px 1.5%;
  display: flex;
  justify-content: space-between;
}
.mailListLeft,
.mailListRight{
  background-color: #fff;
  border-radius: 6px;
}
.mailListLeft{
  width: 18%;
  padding: 24px;
  position: relative;
}
.mailListRight{
  width: 77.5%;
}
.mailListIpt{
  display: flex;
}
.mailListIpt input{
  border-radius: 4px 0 0 4px;
  text-indent: 1em;
  width: 90%;
}
.mailListTree{
  margin-top: 50px;
}
.Rund{
  cursor: pointer;
  transform: rotate(90deg);
  z-index: 999;
}
.customtreenode{
  width: 80% !important;
}
.TablesList{
  margin: 0;
}
.Modification{
  color: #0081FF;
  float: right;
  cursor: pointer;
}
.Quit{
  position: absolute;
  top: 1.5%;
  left: 22%;
  height: 18px;
  line-height: 18px;
}
.mailListQuit{
  padding-left: 30px;
}
.ReasonQuit .EditNewFormItem{
  margin-top: 20px;
}
.Holiday{
  display: flex;
  margin-top: 20px;
}
.Holiday div:nth-child(1){
  width: 150px;
  text-align: left;
}
.EditNewFormBtns .basetable{
  width: 95%;
  margin-top: 20px;
}
</style>