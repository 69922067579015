<template>
 <div class="Resigned_I">
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="fanhui()"><i class="el-icon-arrow-left"></i>返回</div>
        <div class="NavLiDelte" @click="batchDel()"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入姓名">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="basetable"
        style="width: 100%"
        ref="multipleTable"
        header-cell-class-name="basetable-header"
        @selection-change="Selection">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column align="center" prop="id" label="ID"></el-table-column>
        <el-table-column align="center" prop="staff.realname" label="姓名"></el-table-column>
        <el-table-column align="center" prop="reason" label="部门"></el-table-column>
        <el-table-column align="center" prop="reason" label="职责"></el-table-column>
        <el-table-column align="center" prop="quit_time_text" label="离职时间"></el-table-column>
        <el-table-column align="center" prop="reason" label="离职类型"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
              <div class="el_butto" >
                <el-button
                type="text"
                @click="HandleEdit(scope.row)" 
                v-if="TextMoMo('company:staff:edit')"
                >查看</el-button>
              </div>
            <div class="el_butto rere" >
                <el-button
                type="text"
                class="red"
                v-if="TextMoMo('company:staff:del')"
                @click="HandleDel(scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="show" v-if="ruleFormRef2">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
          <div class="NavLiQuery Quit" @click="HandleQuit()" v-if="isInfo == 1">办理离职</div>
        </div>
        <div class="EditNewForm  ReasonQuit">
          <div class="EditNewFormBtns NewFormBtn">
            <div class="EditNewFormItemName">（豆腐网）</div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">离职原因</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row2.reason" placeholder="请填写离职原因" disabled></el-input>
            </div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">离职时间</div>
            <div class="EditNewFormItemIpt">
              <el-date-picker v-model="Row2.quit_time" type="date" style="width:100%" disabled placeholder="选择日期"></el-date-picker>
            </div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">离职类型</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row2.type" placeholder="请选择离职类型" disabled>
                <el-option v-for="item in quitType" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="EditNewFormItem">
            <div class="EditNewFormItemName">办理状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row2.quit_status" placeholder="请选择办理状态" disabled>
                <el-option v-for="item in HandlingStatus" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </div>
          <div class="EditNewFormBtns">
            <div class="Holiday">
              <div>将员工拥有的资源：</div>
              <div>
                <el-radio disabled v-model="Row2.is_transfer" :label="0">直接删除</el-radio>
                <el-radio disabled v-model="Row2.is_transfer" :label="1">转移资源给其他员工</el-radio>
              </div>
            </div>
          </div>
          <div class="EditNewFormBtns" v-if="Row2.is_transfer == 1">
            <el-table
              :data="Row2.transfer"
              class="basetable"
              ref="multipleTable"
              header-cell-class-name="basetable-header">
              <el-table-column prop="name" label="资源"></el-table-column>
              <el-table-column prop="people" label="描述"></el-table-column>
              <el-table-column label="接收人">
                <template #default="scope">
                  <div class="fgfhsdg">
                    <div v-for="(item,i) in Row2.transfer" :key="i" >
                      <div v-if="item.to_staff != null ">
                        <el-input v-if="i == scope.$index" v-model="item.to_staff.realname" placeholder="选择接收人"></el-input>
                      </div>
                      <div v-if="item.to_staff == null ">
                        <el-input v-if="i == scope.$index" v-model="RRealname" placeholder="选择接收人" disabled></el-input>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="EditNewFormBtns">
             <div class="FormBtns">
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </div>
        </div>
      </div>
    </div>







 </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../../components/utils.vue";
export default {
  data(){
    return{
      tableData:[],//总列表
      StaffList:[],
      DepartmentList:[],
      checkList:[],
      Parents:[], //通讯录层级
      HandlingStatus:[{name:'待交接',id: 10},{name:'已交接',id: 20},{name:'不需交接',id: 30}],
      quitType:[{id:10,name:'主动离职'}, {id:20,name:'被动��职'}],
      total: '',
      multipleSelection_id:'',
      titleMath:'',
      editId:'',
      Chan_jieshouren:'',
      staffId:'',
      Chan_Tonx:null,
      ruleFormRef2:false,
      dialogVisible6:false,
      Blo_Black:false,
      page: {  // 分页
        currentPage: 1,
        pageSize: 10,
      },
      search: {  //搜索参数
        keyword: "",
      },
      Row2:{
        staff_id:'',
        reason:'',
        type:'',
        quit_status:'',
        quit_time:'',
        is_transfer:'',
        transfer:[{
            name:'订单资源',
            people:'转移所有档案',
            type: "order", //离职类型:10=主动离职,20=被动离职
            to_staff_id: 0, //接收人
            to_staff:{
              realname:'',    //接收人
            }
          },{
            name:'订单资源',
            people:'转移所有档案',
            type: "order", //离职类型:10=主动离职,20=被动离职
            to_staff_id: 0, //接收人
            to_staff:{
              realname:'',    //接收人
            }
          },{
            name:'订单资源',
            people:'转移所有档案',
            type: "order", //离职类型:10=主动离职,20=被动离职
            to_staff_id: 0, //接收人
            to_staff:{
              realname:'',    //接收人
            }
        }],
      }
    }
  },
  mounted(){
    var that = this
    that.loadTable()
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'GET',
        url: '/admin/company/staff/quitList',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    }, 
    // 获取编辑内容
    HandleEdit(data) {                        
      var that = this
      that.ruleFormRef2 = true;
      that.titleMath = '离职信息'
      that.editId = data.id
      axios.get('/admin/company/staff/quitInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row2 = {
            staff_id: res.data.data.row.staff_id,
            reason: res.data.data.row.reason,
            type: res.data.data.row.type_text,
            quit_status: res.data.data.row.quit_status_text,
            quit_time: res.data.data.row.quit_time_text,
            is_transfer: res.data.data.row.is_transfer,
          }
          var ccc = {name:'订单资源',people:'转移所有档案',}
          const num = res.data.data.row.transfer
          for(var i = 0; i < num.length; i++){
            console.log(12456748);
            num[i].name = ccc.name
            num[i].people = ccc.people
          }
          that.Row2.transfer = num
        }
      })
    },
    Selection(e) {    // 勾选成员
      var that = this
      var arr = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
        }
        that.multipleSelection_id = arr
      }
    },
    //批量删除
    batchDel(){
      var that = this
      if (that.multipleSelection_id == null) {
        that.$message.warning('请勾选员工');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
        }).then(() => {
          axios.delete('/admin/company/staff/delQuit',{   // 模块
            params:{
              ids: that.multipleSelection_id
            }
          }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else{ 
              that.$message.success(res.data.msg);
              that.loadTable()
            }
          })
        })        
      }
    },
    //删除弹出框
    HandleDel(data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/company/staff/delQuit',
          data: {
            ids : arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //返回上一页
    fanhui(){
      this.$emit("childFn",this.info_show)
    },
    handleCurrentChange(page) {  //分页
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    Close(){  //关闭按钮
      var that = this
      that.Chan_Tonx = null
      that.ruleFormRef2 = false;
      that.Row2 = {}
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
  }
}
</script>

<style scoped>
.ReasonQuit .EditNewFormItem{
  margin-top: 20px;
}
.Holiday{
  display: flex;
  margin-top: 20px;
}
.Holiday div:nth-child(1){
  width: 150px;
  text-align: left;
}
.EditNewFormBtns .basetable{
  width: 95%;
  margin-top: 20px;
}
</style>